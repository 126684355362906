<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_recommend_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_recommend_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<!-- <a-button type="primary">新增单位</a-button> -->
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="标题" class="ui-form__item">
						<a-input v-model:value="formModal.title"></a-input>
					</a-form-item>
					
					<a-form-item label="反馈时间" class="ui-form__item">
						<a-range-picker show-time v-model:value="clearTime"></a-range-picker>
					</a-form-item>
					
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'content'">标题</a-checkbox>
							<a-checkbox :value="'createTime'">反馈时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'position'">
							<!-- <div v-if="record.position == 1">科长</div>
							<div v-if="record.position == 2">主任</div>
							<div v-if="record.position == 3">股长</div>
							<div v-if="record.position == 4">局长</div>
							<div v-if="record.position == 5">副局长</div> -->
							{{record.position}}
						</template>
						<template v-if="column.key === 'title'">
							<div>
								<a-tooltip>
								    <template #title>{{ record.title }}</template>
								    <div class="ui-overflow">{{ record.title }}</div>
								  </a-tooltip>
							</div>
						</template>
						<template v-if="column.key === 'content'">
							<div>
								<a-tooltip>
								    <template #title>{{ record.content }}</template>
								    <div class="ui-overflow">{{ record.content }}</div>
								  </a-tooltip>
							</div>
						</template>
						<template v-if="column.key === 'img'">
							<template v-if="record.imgUrl == '[]'">
								-
							</template>
							<template v-else>
								<a-image :src="handleImgUrl(record.imgUrl)[0]" :width="100" :preview="{visible:false}" @click="showImgList(record.imgUrl)"></a-image>
							</template>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 1" type="text" disabled size="small">已读</a-button>
							<a-button v-permission="['pc_system_recommend_read']" v-else type="link" size="small" @click="onChangeStatus(record.id)">未读</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'position'">
							<div v-if="record.position == 1">科长</div>
							<div v-if="record.position == 2">主任</div>
							<div v-if="record.position == 3">股长</div>
							<div v-if="record.position == 4">局长</div>
							<div v-if="record.position == 5">副局长</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 1" type="text" disabled size="small">已读</a-button>
							<a-button v-permission="['pc_system_recommend_read']" v-else type="link" size="small" @click="onChangeStatus(record.id)">未读</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
			<div style="display:none">
				<a-image-preview-group :preview="{visible,onVisibleChange: vis => (visible = vis)}">
					<a-image v-for="item in imgList" :src="item"></a-image>
				</a-image-preview-group>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import { getFeedBack, getFeedBackProcesse } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				getDataFun: getFeedBack,
				clearTime: [], // 存储时间 用来清空重置
				list: [],
				type: 'remarkMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				visible:false,
				imgList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 80
				}, {
					title: '反馈者姓名',
					align: 'center',
					dataIndex: 'nickname'
				}, {
					title: '反馈者手机号',
					align: 'center',
					dataIndex: 'phone'
				}, {
					title: '反馈者职位',
					align: 'center',
					key: 'position',
					// dataIndex: 'position'
				}, {
					title: '反馈者部门',
					align: 'center',
					dataIndex: 'departmentName'
				}, {
					title: '反馈者单位',
					align: 'center',
					dataIndex: 'unitName'
				}, {
					title: '标题',
					align: 'center',
					dataIndex: 'title',
					width: 300,
					key: 'title'
				}, {
					title: '内容',
					align: 'center',
					dataIndex: 'content',
					width: 300,
					key: 'content'
				}, {
					title: '图片信息',
					align: 'center',
					dataIndex: 'img',
					width: 200,
					key: 'img'
				}, {
					title: '反馈时间',
					align: 'center',
					key: 'createTime',
				}, {
					title: '状态',
					align: 'center',
					key: 'action',
					fixed:'right'
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getFeedBack({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("feedback",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				if (this.clearTime && this.clearTime.length) {
					this.searchData.startTime = this.clearTime[0].unix();
					this.searchData.endTime = this.clearTime[1].unix();
				}
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			async onChangeStatus(item) {
				this.loading = true
				try {
					let ret = await getFeedBackProcesse({
						id: item
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$nextTick(() => {
							this.$refs.cTable.toQuery();
						})
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			},
			// 以,切割字符串
			handleImgUrl(string){
				let list = string.split(',');
				return list;
			},
			showImgList(string){
				this.visible = true;
				this.imgList = this.handleImgUrl(string);
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
	.ui-overflow {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
</style>